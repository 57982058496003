import { NgModule, ModuleWithProviders, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollService } from '../scroll.service';
import { GalleryService } from '../gallery.service';
import { TranslateService } from '../translate.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    ScrollService,
    GalleryService,
    TranslateService
  ]
})
export class CoreModule {
  constructor (@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
