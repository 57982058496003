import { Component, OnInit, AfterViewInit, Input, ElementRef } from '@angular/core';
import { GalleryType, ImageURLs } from '../GalleryType';
import { ScrollService } from '../scroll.service';
import { TranslateService } from '../translate.service';

@Component({
  selector: 'app-my-simple-gallery',
  templateUrl: './my-simple-gallery.component.html',
  styleUrls: ['./my-simple-gallery.component.css']
})
export class MySimpleGalleryComponent implements AfterViewInit, OnInit {

  public currentBigImage: CurrentImage;
  public galleryImageURLs: Array<ImageURLs>;
  currentSmallImages: Array<CurrentImage>;
  nbImages: number;
  nbSmallImages: number;

  @Input() gallery: GalleryType;

  constructor(public scrollService: ScrollService, private elRef: ElementRef, private translateService: TranslateService) { }

  ngOnInit() {
    this.galleryImageURLs = this.gallery.getImagesForGallery();
    this.nbImages = this.galleryImageURLs.length;
    this.currentBigImage = new CurrentImage(this.galleryImageURLs[0].big, 0);
    this.currentSmallImages = new Array<CurrentImage>();
    this.galleryImageURLs.forEach((image, i) => {
      this.currentSmallImages.push(new CurrentImage(image.small, i));
    });
  }

  ngAfterViewInit(): void {
    const elem = this.elRef.nativeElement.firstElementChild;
    this.scrollService.registerScroller(this.gallery.specificKey, elem.offsetTop, elem.scrollHeight + elem.offsetTop);
  }

  updateCurrentImage(index: number) {
    this.currentBigImage = new CurrentImage(this.galleryImageURLs[index].big, index);
  }

}

class CurrentImage {
  constructor(public url: String, public index: number) { }
}
