import { Component, OnInit, AfterViewInit, Input, ElementRef } from '@angular/core';
import { GalleryType } from '../GalleryType';
import { ScrollService } from '../scroll.service';

@Component({
  selector: 'app-simple-images',
  templateUrl: './simple-images.component.html',
  styleUrls: ['./simple-images.component.css']
})
export class SimpleImagesComponent implements OnInit, AfterViewInit {

  @Input() gallery: GalleryType;

  constructor(public scrollService: ScrollService, private elRef: ElementRef) { }

  ngOnInit() {
  }
  ngAfterViewInit(): void {
    const elem = this.elRef.nativeElement;
    this.scrollService.registerScroller(this.gallery.specificKey, elem.offsetTop, elem.scrollHeight + elem.offsetTop);
  }
}
