import { Component, OnInit, AfterViewInit, ElementRef, Input } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { ScrollService } from '../scroll.service';
import { GalleryType } from '../GalleryType';

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.css']
})
export class ImagesComponent implements OnInit, AfterViewInit {

  @Input() gallery: GalleryType;

  galleryOptions: NgxGalleryOptions[];
  galleryImagesByRoomType: NgxGalleryImage[];

  constructor(public scrollService: ScrollService, private elRef: ElementRef) {
  }

  ngOnInit() {
    this.galleryOptions = [
      {
        thumbnailsColumns: 5,
        thumbnailsRows: 3,
        thumbnailsPercent: 100,
        imagePercent: 0,
        image: false,
        thumbnailMargin: 2,
        thumbnailsMargin: 2,
        width: '100%',
        thumbnailsOrder: 2
      },
      {
        breakpoint: 500,
        width: '100%',
        height: '300px',
        thumbnailsColumns: 5
      },
      {
        breakpoint: 300,
        width: '100%',
        height: '200px',
        thumbnailsColumns: 3
      }

      // {
      //   width: '100%',
      //   // width: '800px',
      //   height: '600px',
      //   // imageInfinityMove: true,
      //   // imageAutoPlay: true,
      //   // imageArrows: false,
      //   imageSwipe: true,
      //   image: false,
      //   // imageAnimation: NgxGalleryAnimation.Slide,
      //   thumbnailsArrows: true,
      //   thumbnailsRemainingCount: true,
      //   thumbnailsSwipe: true,
      //   previewSwipe: true,
      //   previewZoom: true,
      //   previewCloseOnClick: false,
      //   previewCloseOnEsc: true,
      //   previewKeyboardNavigation: true,
      //   thumbnailsRows: 3,
      //   thumbnailsColumns: 15,
      //   previewInfinityMove: true
      // },
      // {
      //   breakpoint: 800,
      //   width: '800px',
      //   thumbnailsRows: 3,
      //   height: '600px',
      //   imagePercent: 0,
      //   thumbnailsPercent: 100,
      //   thumbnailsMargin: 20,
      //   thumbnailMargin: 20
      // },
      // {
      //   breakpoint: 800,
      //   preview: false
      // }
    ];
  }

  ngAfterViewInit(): void {
    const elem = this.elRef.nativeElement;
    this.scrollService.registerScroller(this.gallery.specificKey, elem.offsetTop, elem.scrollHeight + elem.offsetTop);
  }
}
