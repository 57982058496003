import { Component, HostListener } from '@angular/core';
import { Parallax, ParallaxConfig } from 'ngx-parallax';
import { ScrollService } from './scroll.service';
import { GalleryService } from './gallery.service';
import { TranslateService } from './translate.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [ScrollService, GalleryService, TranslateService]
})

export class AppComponent {
  parallaxConfig: ParallaxConfig = {
    initialValue: 0,
    ratio: .3
  };

  lang = new Language('en', 'English');

  constructor(private scrollService: ScrollService, private galleryService: GalleryService, private translate: TranslateService) {
    this.setLang();
  }

  setLang(langCode?: string, langName?: string) {
    if (langCode !== undefined) {
      this.lang = new Language(langCode, langName);
    }
    this.translate.use(this.lang.code);
  }

  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    this.scrollService.activeScroll($event);
  }
}

class Language {
  constructor(public code: string, public name: string) { }
}
