import { Component, OnInit, ElementRef, AfterViewInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AngularFireDatabase } from 'angularfire2/database';
import { ScrollService } from '../scroll.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit, AfterViewInit {
  emailForm: FormGroup;
  constructor(private fb: FormBuilder, private db: AngularFireDatabase, public scrollService: ScrollService, private elRef: ElementRef) { }

  ngAfterViewInit(): void {
    const elem = this.elRef.nativeElement.parentElement;
    this.scrollService.registerScroller(elem.id, elem.offsetTop, elem.scrollHeight + elem.offsetTop);
  }
  
  ngOnInit() {
    this.emailForm = new FormGroup({
      'name': new FormControl('', [Validators.required]),
      'email': new FormControl('', [Validators.required, Validators.email]),
      'message': new FormControl('', [Validators.required])
    });
  }

  onSubmit() {
    const { name, email, message } = this.emailForm.value;
    const date = Date();
    const html = `
      <div>From: ${name}</div>
      <div>Email: <a href="mailto:${email}">${email}</a></div>
      <div>Date: ${date}</div>
      <div>Message: ${message}</div>
    `;
    const formRequest = { name, email, message, date, html };
    this.db.list('/messages').push(formRequest);
    this.emailForm.reset();
  }

  get name() { return this.emailForm.get('name'); }
  get email() { return this.emailForm.get('email'); }
  get message() { return this.emailForm.get('message'); }

}
