export class ImageType {
    projectKey: String;
    specificKey: String;
    categoryKey: String;
    imageNumber: number;
    pathToImage: String;

    constructor(projectKey: String, specificKey: String, categoryKey: String, imageNumber: number) {
        this.projectKey = projectKey;
        this.specificKey = specificKey;
        this.categoryKey = categoryKey;
        this.imageNumber = imageNumber;

        this.pathToImage = projectKey + '-' + specificKey + '-' + imageNumber;
    }

    public getFullPathToImage(size: String): String {
        return './../assets/picturesToDisplay/' + this.projectKey + '/' + this.specificKey + '/' + size + '-' + this.projectKey + '-' +
            this.categoryKey + '-' + this.imageNumber + '.jpg';
    }

}
