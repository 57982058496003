import { Component, OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { ScrollService } from '../scroll.service';


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements AfterViewInit {
  constructor(public scrollService: ScrollService, private elRef: ElementRef) { }

  ngAfterViewInit(): void {
    const elem = this.elRef.nativeElement.parentElement;
    this.scrollService.registerScroller(elem.id, elem.offsetTop, elem.offsetTop + elem.scrollHeight + 40);
  }

}
