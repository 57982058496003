import { Component, OnInit, ElementRef, AfterViewInit } from '@angular/core';

import { GalleryService } from './../gallery.service';

import { ScrollService } from '../scroll.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit, AfterViewInit {

  scrollService: ScrollService;
  galleryService: GalleryService;

  constructor(scrollService: ScrollService, galleryService: GalleryService, private elRef: ElementRef) {
    this.scrollService = scrollService;
    this.galleryService = galleryService;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const elem = this.elRef.nativeElement.parentElement;
    this.scrollService.registerScroller(elem.id, elem.offsetTop, elem.scrollHeight + elem.offsetTop);
  }

}
