import { Injectable } from '@angular/core';
// TODO: Inject JQuery properly
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  scrollOffset: number;
  scrollOffsetRatio = 5;
  scrollHeights: Array<PositionOnPage> = [];

  public registerScroller(id: String, top: number, bottom: number) {
    if (this.scrollHeights.find(val => val.id === id) === undefined) {
      this.scrollHeights.push(new PositionOnPage(id, top, bottom));
    }
  }

  public activeScroll($event: Event): void {
    const offset: number = window.scrollY + window.innerHeight / 2;
    const targetForScroll: any = this.scrollHeights.find(val => offset > val.top && offset < val.bottom);
    let targetId: String;
    if (targetForScroll === undefined) {
      targetId = 'welcome';
    } else {
      targetId = targetForScroll.id;
    }
    const target = $('#' + targetId + '-nav');
    const parent: any = target.parents('#ac-navbar');
    const allNavs: any = parent.find('.ac-nav-item');
    allNavs.removeClass('active');
    const dropdownHeader: any = target.parents('.nav-item.dropdown');
    if (dropdownHeader.length === 1) {
      dropdownHeader.children('.dropdown-toggle').addClass('active');
    }
    target.addClass('active');
  }

  public scroll($event: Event, id: string): void {
    const element: HTMLElement = document.getElementById(id);
    const heightOnPage: number = element.offsetTop;
    const navbarOffset: number = document.getElementsByTagName('nav').item(0).getBoundingClientRect().height;

    const target: Element = $event.srcElement;
    const de = document.documentElement;
    const box = target.getBoundingClientRect();
    const top = box.top + window.pageYOffset - de.clientTop;

    const scrollToLocation = heightOnPage - navbarOffset;

    this.smoothScroll(scrollToLocation, top);
  }
  private smoothScroll(scrollToHeight: number, currentHeight: number, nextScrollTo: number = 0): void {
    nextScrollTo = nextScrollTo || window.scrollY;
    if (nextScrollTo < scrollToHeight) {
      setTimeout(() => {
        window.scrollTo(0, nextScrollTo);
        this.scrollOffset = Math.max(Math.abs(scrollToHeight - currentHeight) / this.scrollOffsetRatio, 25);
        const scrollAmount: number = nextScrollTo + this.scrollOffset;
        if (scrollAmount > scrollToHeight) {
          this.smoothScroll(scrollToHeight, currentHeight + this.scrollOffset, scrollToHeight);
        } else {
          this.smoothScroll(scrollToHeight, currentHeight + this.scrollOffset, scrollAmount);
        }
      }, 10);
    } else if (nextScrollTo > scrollToHeight) {
      setTimeout(() => {
        window.scrollTo(0, nextScrollTo);
        this.scrollOffset = Math.max(Math.abs(scrollToHeight - currentHeight) / this.scrollOffsetRatio, 25);
        const scrollAmount: number = nextScrollTo - this.scrollOffset;
        if (scrollAmount < scrollToHeight) {
          this.smoothScroll(scrollToHeight, currentHeight - this.scrollOffset, scrollToHeight);
        } else {
          this.smoothScroll(scrollToHeight, currentHeight - this.scrollOffset, scrollAmount);
        }
      }, 10);
    }
  }
  constructor() { }
}

class PositionOnPage {
  constructor(public id: String, public top: number, public bottom: number) { }
}
