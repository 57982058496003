import { Component, ElementRef, AfterViewInit } from '@angular/core';
import { ScrollService } from '../scroll.service';
declare var $: any;

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements AfterViewInit {

  constructor(public scrollService: ScrollService, private elRef: ElementRef) { }

  ngAfterViewInit(): void {
    const elem = this.elRef.nativeElement.parentElement;
    const navbarHeight: number = $('#ac-navbar').outerHeight() + 6;
    elem.style.marginTop = navbarHeight + 'px';
    this.scrollService.registerScroller(elem.id, elem.offsetTop, elem.scrollHeight + elem.offsetTop);
  }

}
