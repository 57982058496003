import { ImageType } from './ImageType';

export class GalleryType {

    specificKey: String;
    categoryKey: String;
    images: Array<ImageType>;

    constructor(key: String, images: Array<ImageType>) {
        this.specificKey = key;
        if (key.charAt(key.length - 1) === key.charAt(key.length - 1).toUpperCase()) {
            this.categoryKey = key.slice(0, -1);
        } else {
            this.categoryKey = key;
        }
        this.images = images;
    }

    public getImagesForGallery(): Array<ImageURLs> {
        return this.images.map((image: ImageType) => {
            return new ImageURLs(
                image.getFullPathToImage('small'),
                image.getFullPathToImage('medium'),
                image.getFullPathToImage('big')
            );
        });
    }
}

export class ImageURLs {
    constructor(
        public small: String,
        public medium: String,
        public big: String) { }
}
