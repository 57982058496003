import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';

import { RouterModule, Routes } from '@angular/router';
import { WelcomeComponent } from './welcome/welcome.component';
import { GalleryComponent } from './gallery/gallery.component';
import { ContactComponent } from './contact/contact.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { ParallaxModule } from 'ngx-parallax';

import 'hammerjs';

import { NgxGalleryModule } from 'ngx-gallery';

import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { environment } from '../environments/environment';
import { ReactiveFormsModule } from '@angular/forms';
import { AboutComponent } from './about/about.component';
import { ServicesComponent } from './services/services.component';

import { CoreModule } from './core/core.module';
import { ImagesComponent } from './images/images.component';
import { SimpleImagesComponent } from './simple-images/simple-images.component';
import { MySimpleGalleryComponent } from './my-simple-gallery/my-simple-gallery.component';

import { HttpClientModule } from '@angular/common/http';
import { TranslateService } from './translate.service';
import { TranslatePipe } from './translate.pipe';

const appRoutes: Routes = [
  { path: 'welcome', component: WelcomeComponent },
  { path: 'gallery/:house', component: GalleryComponent },
  { path: 'contact',      component: ContactComponent },
  {
    path: 'gallery',
    component: GalleryComponent,
    data: { title: 'Gallery Home' }
  },
  { path: '',
    redirectTo: '/',
    pathMatch: 'full'
  },
  { path: '**', component: PageNotFoundComponent }
];

export function setupTranslateFactory(
  service: TranslateService): Function {
  return () => service.use('en');
}

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    GalleryComponent,
    ContactComponent,
    PageNotFoundComponent,
    AboutComponent,
    ServicesComponent,
    ImagesComponent,
    SimpleImagesComponent,
    MySimpleGalleryComponent,
    TranslatePipe
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    ),
    NgxGalleryModule,
    BrowserModule,
    ReactiveFormsModule,
    ParallaxModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    CoreModule,
    HttpClientModule
  ],
  providers: [
    TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [ TranslateService ],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
