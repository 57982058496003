import { Component, ElementRef, AfterViewInit } from '@angular/core';
import { ScrollService } from '../scroll.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements AfterViewInit {

  constructor(public scrollService: ScrollService, private elRef: ElementRef) { }

  ngAfterViewInit(): void {
    const elem = this.elRef.nativeElement.parentElement;
    this.scrollService.registerScroller(elem.id, elem.offsetTop, elem.scrollHeight + elem.offsetTop);
  }

}
